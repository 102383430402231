import classnames from "classnames";
import React, { FC } from "react";
import { useHotkeys } from "react-hotkeys-hook";

import { ChevronLeft, ChevronRight } from "~/components/icons";

import { DOTS, usePagination } from "./usePagination";

type PaginationProps = {
  totalCount: number;
  pageSize?: number;
  currentPage?: number;
  siblingCount?: number;
  onPageChange: (newPage: number) => void;
};

const Pagination: FC<React.PropsWithChildren<PaginationProps>> = ({
  totalCount,
  pageSize = 200,
  currentPage = 1,
  siblingCount = 1,
  onPageChange,
}) => {
  const paginationRange = usePagination(totalCount, pageSize, currentPage, siblingCount);

  const lastPage = paginationRange[paginationRange.length - 1] as number;

  const onNext = () => {
    const newPage = currentPage + 1;
    if (paginationRange.length == 0 || newPage > lastPage) return;
    onPageChange(newPage);
  };

  const onPrevious = () => {
    const newPage = currentPage - 1;
    if (paginationRange.length == 0 || newPage < 1) return;
    onPageChange(newPage);
  };

  //https://johannesklauss.github.io/react-hotkeys-hook/docs-use-hotkeys
  useHotkeys(",", () => onPrevious(), [currentPage, totalCount]);
  useHotkeys(".", () => onNext(), [currentPage, totalCount]);

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const pagination_item_classname =
    "flex items-center px-4 h-8 hover:bg-gray-100/50 rounded hover:cursor-pointer select-none";

  return (
    <ul className="flex justify-center">
      <li
        className={classnames(pagination_item_classname, {
          "pointer-events-none text-opacity-5": currentPage === 1,
        })}
        onClick={onPrevious}
      >
        <ChevronLeft />
      </li>

      {paginationRange.map((pageNumber, index) => {
        if (pageNumber === DOTS) {
          return (
            <li
              key={pageNumber + index}
              className="flex h-8 cursor-default select-none items-center rounded px-4 hover:cursor-pointer hover:bg-gray-100/50"
            >
              &#8230;
            </li>
          );
        }

        return (
          <li
            key={pageNumber}
            className={classnames(pagination_item_classname, {
              "bg-white": pageNumber === currentPage,
            })}
            onClick={() => onPageChange(+pageNumber)}
          >
            {pageNumber}
          </li>
        );
      })}
      <li
        className={classnames(pagination_item_classname, {
          "pointer-events-none text-opacity-50": currentPage === lastPage,
        })}
        onClick={onNext}
      >
        <ChevronRight />
      </li>
    </ul>
  );
};

export default React.memo(Pagination);
